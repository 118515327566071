.analytics-index{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 12px 0;
}

.analytic-card{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #7070701d;
    box-sizing: border-box;
    max-width: 280px;
    width: 100%;
    max-height: 320px;
    min-height: 320px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    &:hover{
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .09);
    }
    h1{
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 12px;
        border-bottom: 1px solid #7070701d;
        margin: 0;
    }
    .analytics-body{
        padding: 12px;
        overflow-y: auto;
        min-height: 270px;
        max-height: 270px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}

*[name-tooltip] {
    position: relative;
}

*[name-tooltip]:hover::after {
    opacity: 1;
}

*[name-tooltip]::after {
    content: attr(name-tooltip);

    position: absolute;
    top: -20px;
    right: 20px;
    left: 10px;
    width: 180px;

    pointer-events: none;
    opacity: 0;
    -webkit-transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -ms-transition: opacity .15s ease-in-out;
    -o-transition: opacity .15s ease-in-out;
    transition: opacity .15s ease-in-out;

    display: block;
    font-size: 12px;
    line-height: 16px;
    background: #c2cff7;
    padding: 2px 2px;
    border: 1px solid #c0c0c0;
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.4);
}